// Here you can add other styles

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.form-control {
    border-color: #9da5b1;
}

.form-control-sm {
    min-height: 38px;
    border-color: #9da5b1;
}

textarea.custom-textarea {
  font-size: 14px;
}

.table-container {
    overflow-y: auto;
    max-height: 68vh;
}

.topbar-buttons-container {
    white-space: nowrap;
    overflow-x: auto;
}

.dropdown-menu-custom {
  width: 400px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

@media (max-width: 768px) {
  .dropdown-mobile .dropdown-menu-custom {
    width: 100vw; 
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.header-filter {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.header-filter::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  z-index: 11;
}

.header-filter::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #d8dbe0;
  z-index: 11;
}

.linearIndeterminateContainer {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #fff; //#e0e0e0;
  border-radius: 0px;
  overflow: hidden;
}

.linearIndeterminateBar {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #2eb85c;
  border-radius: 0px;
  animation: linear-progress 1.5s infinite;
  transform-origin: left;
}

@keyframes linear-progress {
  0% {
    transform: scaleX(0);
    opacity: 1;
  }
  50% {
    transform: scaleX(0.5);
    opacity: 0.6;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}

.nav-link {
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #fff;
    width: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #9da5b1;
    border-radius: 10px;
}

::-webkit-file-upload-button {
    min-height: 37px;
}


